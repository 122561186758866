@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins:wght@400;600;900&display=swap");

#root {
  display: flex;
}

.body {
  padding: 0;
  margin: 0;
}
